<template>
  <v-app dark id="ra-editor">
    <v-app-bar color="primary" app dark>
      <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon v-if="isLoading" :loading="true"></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="openJsonEditor">
              <v-icon>{{ icons.mdiCodeJson }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('edit_json') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogThresholds = true">
              <v-icon>{{ $vuetify.icons.values.threshold }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('thresholds') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="openSave" :loading="isSaving">
              <v-icon>{{ icons.mdiContentSave }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('save') }}</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-app-bar>

    <v-dialog v-model="dialogThresholds" max-width="400">
      <v-card>
        <v-card-title>{{ $t('thresholds') }}</v-card-title>
        <v-card-text>
          <v-text-field type="number" :label="$t('yellow')" v-model="thresholds.yellow"></v-text-field>
          <v-text-field type="number" :label="$t('red')" v-model="thresholds.red"></v-text-field>
          <v-text-field type="number" :label="$t('critical')" v-model="thresholds.critical"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialogThresholds = false">{{ $t('button_close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="setThresholds">{{ $t('button_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogJson">
      <v-card>
        <v-card-title>{{ $t('edit_json') }}</v-card-title>
        <v-card-text>
          <vue-json-editor
              v-model="editorJson" :show-btns="false"
              :expanded-on-start="false"
              mode="tree"></vue-json-editor>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialogJson = false">{{ $t('button_close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="saveJson">{{ $t('button_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSave" max-width="400">
      <v-card>
        <v-card-title>{{ $t('save') }}</v-card-title>
        <v-card-text>
          <v-checkbox v-model="saveAsCopy" :disabled="ra.id === 'new'"
                      :label="$t('save_as_copy')"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialogSave = false">{{ $t('button_cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="save">{{ $t('button_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-main>
        <v-form>
          <div class="d-flex align-center justify-space-between mt-2 mb-4">
            <v-text-field v-model.trim="title"
                          outlined
                          dense
                          hide-details
                          :label="$t('title')"
                          class="mr-4"
                          @change="isChanged = true">
            </v-text-field>
            <v-select
                v-model="lang"
                outlined
                dense
                hide-details
                :items="availableLanguages"
                class="lang-selector mr-4"
            ></v-select>
            <v-checkbox hide-details class="ma-0 mr-4" v-model="ra.supplemental"
                        :label="$t('supplemental')"></v-checkbox>
            <v-checkbox hide-details class="ma-0" v-model="ra.auto"
                        :label="$t('automation')"></v-checkbox>
          </div>
          <v-divider class="my-4"></v-divider>
          <div id="fields">
            <div class="field"
                 v-for="(field, index) in fields"
                 :key="index">
              <drop class="drop mb-7"
                    :class="{ over }"
                    @dragover="over = true"
                    @dragleave="over = false"
                    @drop="handleDrop(index, ...arguments)">
                <drag :transfer-data="{index: index}">
                  <v-card>
                    <v-card-text>
                      <field
                          :field="field"
                          :fields="fields"
                          :index="index"
                          @changed="isChanged = true"
                          @delete="deleteField"></field>
                    </v-card-text>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            color="info"
                            fab
                            small
                            absolute
                            bottom
                            right
                            v-on="on"
                            @click="addField(index, 'text')"
                        >
                          <v-icon>{{ icons.mdiPlus }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('add_field') }}</span>
                    </v-tooltip>
                  </v-card>
                </drag>
              </drop>
            </div>
          </div>
        </v-form>
      </v-main>
    </v-container>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout">
      {{ snackbarMessage }}
    </v-snackbar>

  </v-app>
</template>

<style>
.drop.over .v-card {
  background-color: #f7fafc;
}

.lang-selector {
  max-width: 140px;
}

</style>

<script>
import api from './api';
import short from './shortuid';
import {
  mdiCheckboxMultipleBlankOutline,
  mdiCodeJson,
  mdiCogs,
  mdiContentSave,
  mdiDelete,
  mdiFocusFieldHorizontal,
  mdiPlus,
  mdiTag,
  mdiTagOff,
  mdiTagPlus,
} from '@mdi/js';
import Field from './components/Field';
import {Drag, Drop} from 'vue-drag-drop';
import VueJsonEditor from 'vue-json-editor';
import '@/assets/css/vue-json-editor-overrides.css';
import _cloneDeep from 'lodash/cloneDeep';
import {useCoreStore} from './stores/core';

export default {
  name: 'Editor',
  setup() {
    const coreStore = useCoreStore();
    return {coreStore};
  },
  created() {
    this.start();
  },
  props: {
    id: String,
    copyId: String,
    setClientId: String,
  },
  components: {
    Field,
    Drag,
    Drop,
    VueJsonEditor,
  },
  watch: {
    isChanged() {
      if (this.isChanged) {
        window.onbeforeunload = function () {
          return true;
        };
      } else {
        window.onbeforeunload = null;
      }
    }
  },
  computed: {
    appTitle() {
      if (this.isChanged) {
        return this.title.concat(' *');
      } else {
        return this.title;
      }
    },
    fromCopy() {
      return this.copyId && this.copyId !== 'new';
    }
  },
  data: () => ({
    drawer: true,
    width: 256,
    ra: {},
    fields: [],
    editorJson: [],
    thresholds: {
      yellow: 2,
      red: 2.5,
      critical: 0,
    },
    over: false,
    saveAsCopy: false,
    dialogJson: false,
    dialogSave: false,
    dialogThresholds: false,
    title: 'Untitled',
    lang: 'en',
    isLoading: false,
    isSaving: false,
    isChanged: false,
    isError: false,
    timeout: 3000,
    snackbar: false,
    snackbarMessage: '',
    idRule: [
      v => !!v || 'Required'
    ],
    icons: {
      mdiContentSave,
      mdiFocusFieldHorizontal,
      mdiCheckboxMultipleBlankOutline,
      mdiTag,
      mdiTagPlus,
      mdiTagOff,
      mdiPlus,
      mdiDelete,
      mdiCodeJson,
      mdiCogs,
    },
    availableLanguages: [
      {value: 'en', text: 'English'},
      {value: 'sv', text: 'Swedish'}
    ]
  }),
  methods: {
    openSave() {
      this.dialogSave = true;
    },
    openJsonEditor() {
      this.editorJson = _cloneDeep(this.fields);
      this.dialogJson = true;
    },
    saveJson() {
      this.fields = this.editorJson;
      this.$forceUpdate();
      this.isChanged = true;
      this.dialogJson = false;
    },
    handleDrop(target, data) {
      this.isLoading = true;
      this.over = false;
      const source = data.index;
      if (source !== target) {
        this.fields.splice(target, 0, this.fields.splice(source, 1)[0]);
        this.isChanged = true;
        this.$forceUpdate();
      }
      this.isLoading = false;
    },
    async start() {
      this.isLoading = true;
      if (this.copyId === 'new' && this.setClientId) {
        this.title = 'Untitled';
        this.ra.client_id = this.setClientId;
        this.ra.id = 'new';
        this.fields = [];
        this.addField(0, 'text');
        this.isChanged = true;
      } else if (this.id || this.copyId) {
        try {
          this.ra = await api.getModel(this.id || this.copyId);
          if (this.ra.thresholds) {
            this.thresholds = this.ra.thresholds;
          }
          if (this.ra.id) {
            if (this.copyId) {
              this.ra.id = 'new';
              this.ra.client_id = this.setClientId;
            }
            this.title = this.ra.title;
            this.fields = this.ra.fields;
            this.fields.forEach(field => {
              field.automation ??= {};
            });
            this.isChanged = !!this.copyId;
          }
        } catch {
          this.isError = true;
        }
      } else {
        this.isError = true;
      }
      await this.coreStore.loadCommonData();
      this.isLoading = false;
    },
    addField(index, type) {
      let newField = this.newQuestion(type);
      if (this.fields.length > index) {
        this.fields.splice(index + 1, 0, newField);
      } else {
        this.fields.push(newField);
      }
      this.$forceUpdate();
      this.isChanged = true;
    },
    newQuestion(type) {
      return {
        id: short.generate(),
        type: type || 'text',
        weight: 2,
        required: false,
        title: '',
        link: {},
        automation: {},
      };
    },
    setThresholds() {
      this.ra.thresholds = {
        yellow: Number(this.thresholds.yellow) || 0,
        red: Number(this.thresholds.red) || 0,
        critical: Number(this.thresholds.critical) || 0,
      };
      this.dialogThresholds = false;
    },
    deleteField(index) {
      this.fields.splice(index, 1);
      this.isChanged = true;
      this.$forceUpdate();
    },
    async save() {
      this.isSaving = true;
      this.ra.title = this.title;
      this.ra.lang = this.lang;
      for (const field of this.fields) {
        switch (field.type) {
          case 'text':
          case 'number':
          case 'date':
            if (field.options) {
              delete field.options;
            }
            break;
        }
      }
      this.ra.fields = this.fields;
      const copy = this.saveAsCopy;
      this.saveAsCopy = false;
      if (copy || this.ra.id === 'new') {
        this.ra.id = short.generate();
        this.ra.new = true;
      }
      this.isChanged = false;
      const result = await api.saveModel(this.ra);
      this.isSaving = false;
      this.dialogSave = false;
      if (result.success) {
        if (window.opener && window.opener.location) {
          // window.opener.location.reload();
        }
        if (copy || this.copyId) {
          window.onbeforeunload = null;
          window.location.href = `/${this.ra.id}`;
        } else {
          this.snackbarMessage = 'Saved successfully';
          this.snackbar = true;
        }
      } else {
        this.snackbarMessage = 'Could not save';
        this.snackbar = true;
      }
    },
  }
};

</script>
