import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from 'vuetify/lib/locale/en'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('./locales/en.json'),
    $vuetify: en,
  },
}

const startingLocale = process.env.I18N_LOCALE || 'en';

const i18n = new VueI18n({
  locale: startingLocale,
  fallbackLocale: process.env.I18N_FALLBACK_LOCALE || 'en',
  availableLocales: ['en'],
  messages,
});

export default i18n
