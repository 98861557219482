import { defineStore } from 'pinia';
import api from '../../api';

const state = () => ({
   countries: [],
   naceCodes: [],
});

const actions = {
    loadCommonData() {
        return new Promise(resolve => {
            const promises = [
                api.getCountries(),
                api.getNaceCodes(),
            ];
            Promise.all(promises).then(res => {
                const [countries, naceCodes] = res;
                this.countries = countries;
                this.naceCodes = naceCodes;
            }).finally(() => resolve());
        });
    },
};

const getters = {
    getNaceCodes: state => state.naceCodes,
    getCountries: state => state.countries,
};

export const useCoreStore = defineStore('coreStore', {
    state,
    getters,
    actions,
});