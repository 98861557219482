<template>
  <section id="option-table">
    <v-data-table
        :headers="headers"
        :items="matrix"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        dense
        class="w-100">
      <template v-slot:item.value="{item}">
        <span v-if="field.type === 'country'">
          {{ parseCountries(item.value) }}
        </span>
        <span v-else-if="field.type === 'rcountry'">
          {{ parseIndexOption(item.value) }}
        </span>
        <span v-else-if="Array.isArray(item.value)">
          {{ item.value.join(', ') }}
        </span>
        <span v-else>
          {{ item.value }}
        </span>
      </template>
      <template v-slot:item.risk="{item}">
        {{ riskLabels[String(item.risk)] }}
      </template>
      <template v-slot:item.range="{item}">
        <span v-if="item.rangeType && item.rangeValue1">{{ rangeTypes.find(i => i.value === item.rangeType).text }} {{ item.rangeValue1 }}<span v-if="item.rangeValue2">–{{ item.rangeValue2}}</span></span>
      </template>
      <template v-slot:item.flag="{item}">
        <v-icon v-if="item.flag" small :color="flagColors[item.flag]" class="mr-1">
          {{ item.flag === 4 || item.flag === 14 || item.flag === 24 ? $vuetify.icons.values.flagAlert : icons.mdiFlag }}
        </v-icon>
        <span v-if="item.flag">{{ flagLabels[item.flag] }}</span>
      </template>
      <template v-slot:item.combinations="{item}">
        <table v-if="Array.isArray(item.combinations) && item.combinations.length > 0">
          <tr v-for="(combo, index) of item.combinations" :key="index">
            <td class="pl-0">
              {{ combo.fieldTitle }}
              <v-icon small>
                {{ icons.mdiChevronRight }}
              </v-icon>
              {{ combo.optionValue }}
            </td>
            <td>
              <span v-if="combo.riskElevation > 0">+{{ combo.riskElevation }}</span>
              <span v-else-if="combo.riskElevation < 0">-{{ combo.riskElevation }}</span>
              <span v-else>{{ combo.riskElevation }}</span>
            </td>
            <td>
              <v-icon v-if="combo.flag" small :color="flagColors[combo.flag]" class="mr-1">
                {{ combo.flag === 4 || combo.flag === 14 || combo.flag === 24 ? $vuetify.icons.values.flagAlert : icons.mdiFlag }}
              </v-icon>
              <span v-if="combo.flag">{{ flagLabels[combo.flag] }}</span>
            </td>
          </tr>
        </table>
      </template>
      <template v-slot:footer>
        <v-toolbar flat dense color="white">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800">
            <template v-slot:activator="{on}">
              <v-btn fab x-small color="info" dark v-on="on">
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                        v-if="field.type === 'country'"
                        hide-details
                        :items="countries"
                        item-text="name"
                        item-value="code"
                        multiple
                        :label="$t('countries')"
                        v-model="editedItem.value"
                    >
                    </v-autocomplete>
                    <v-select
                        v-else-if="field.type === 'rcountry' || field.type === 'country-risks'"
                        hide-details
                        :items="countryCategories"
                        item-text="label"
                        item-value="value"
                        :label="$t('category')"
                        v-model="editedItem.value"
                    >
                    </v-select>
                    <v-text-field v-if="field.type === 'country-risks'" v-model.trim="editedItem.value"
                                  :label="$t('option')"></v-text-field>
                    <v-text-field v-else v-model.trim="editedItem.value"
                                  :label="$t('option')"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense v-if="!['country', 'rcountry', 'country-risks'].includes(field.type)">
                  <v-col>
                    <v-textarea rows="2" v-model.trim="editedItem.explanation" hide-details
                                :label="$t('explanation')"></v-textarea>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-select v-model="editedItem.risk" :items="riskLevels" item-text="label"
                              item-value="risk"
                              hide-details
                              :label="$t('risk')">
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select v-model="editedItem.flag" :items="flagTypes" item-text="label"
                              item-value="value"
                              hide-details
                              :label="$t('flag')">
                      <template v-slot:append>
                        <v-icon :color="flagColors[editedItem.flag]">
                          {{
                            editedItem.flag === 4 || editedItem.flag === 14 || editedItem.flag === 24 ? $vuetify.icons.values.flagAlert : icons.mdiFlag
                          }}
                        </v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row dense v-if="field.automation && field.automation.type === 'number'">
                  <v-col>
                    <v-select v-model="editedItem.rangeType" :items="rangeTypes" hide-details item-text="text" item-value="value"
                              :label="$t('range_automation')"></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field type="number" v-model="editedItem.rangeValue1" hide-details
                                  :label="`${$t('value')}${editedItem.rangeType === 'between' ? ' 1' : ''}`"></v-text-field>
                  </v-col>
                  <v-col v-if="editedItem.rangeType === 'between'">
                    <v-text-field type="number" v-model="editedItem.rangeValue2" hide-details
                                  :label="`${$t('value')} 2`"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <div class="subtitle-1 my-2">{{ $t('combos') }}</div>
                    <combo-table
                        :option="editedItem"
                        :key="editedItem.id"
                        :field="field"
                        :fields="fields"
                    ></combo-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary" text @click="close">{{ $t('button_cancel') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="save">{{ $t('button_save') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <span class="text-no-wrap">
            <v-btn icon x-small :disabled="Boolean(getIndex(item) === 0)" class="mr-2"
                   @click="move(true, item)">
                <v-icon small>
                    {{ icons.mdiChevronUp }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small :disabled="(getIndex(item) === matrix.length - 1)" class="mr-2"
                   @click="move(false, item)">
                <v-icon small>
                    {{ icons.mdiChevronDown }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small class="mr-2" @click="editItem(item)">
                <v-icon small>
                    {{ icons.mdiPencil }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small @click="deleteItem(item)">
                <v-icon small>
                    {{ icons.mdiDelete }}
                </v-icon>
            </v-btn>
        </span>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiCheck,
  mdiCircle,
  mdiChevronUp,
  mdiChevronDown,
  mdiChevronRight,
  mdiFlag,
} from '@mdi/js';
import short from '../shortuid';
import ComboTable from './ComboTable';
import {useCoreStore} from '../stores/core';
import {mapState} from 'pinia';

export default {
  name: 'OptionTable',
  setup() {
    const coreStore = useCoreStore();
    return {coreStore};
  },
  props: {
    field: Object,
    fields: Array,
  },
  components: {
    ComboTable,
  },
  data: () => ({
    dialog: false,
    search: null,
    matrix: [],
    editedIndex: -1,
    editedItem: {
      id: '',
      value: '',
      risk: 1,
      flag: 0,
      combinations: [],
    },
    defaultItem: {
      id: '',
      value: '',
      risk: 1,
      flag: 0,
      combinations: [],
    },
    requiredRule: [
      v => !!v || 'Required',
    ],
    rangeTypes: [
      {value: null, text: ''},
      {value: 'eq', text: '='},
      {value: 'neq', text: '!='},
      {value: 'lt', text: '<'},
      {value: 'lte', text: '<='},
      {value: 'gt', text: '>'},
      {value: 'gte', text: '>='},
      {value: 'between', text: '<>'},
    ],
    riskLevels: [
      {risk: -1, label: 'N/A'},
      {risk: 0, label: 'No risk'},
      {risk: 1, label: 'Low'},
      {risk: 2, label: 'Medium'},
      {risk: 3, label: 'High'},
      {risk: 4, label: 'Critical'},
    ],
    flagTypes: [
      {value: 0, label: 'No flag'},
      {value: 1, label: 'Green'},
      {value: 2, label: 'Yellow'},
      {value: 3, label: 'Red'},
      {value: 4, label: 'Critical'},
      {value: 11, label: 'Green (not shown)'},
      {value: 12, label: 'Yellow (not shown)'},
      {value: 13, label: 'Red (not shown)'},
      {value: 14, label: 'Critical (not shown)'},
      {value: 21, label: 'Green (forced)'},
      {value: 22, label: 'Yellow (forced)'},
      {value: 23, label: 'Red (forced)'},
      {value: 24, label: 'Critical (forced)'},
    ],
    countryCategories: [
      {value: '$null', label: 'Unknown/Unanswered'},
      {value: 'gac_lt_50', label: 'Evaluate GAC < 50'},
      {value: 'gac_50_70', label: 'Evaluate GAC 50 – 70'},
      {value: 'gac_lt_70', label: 'Evaluate GAC < 70'},
      {value: 'gac_70_80', label: 'Evaluate GAC 70 – 80'},
      {value: 'gac_gt_80', label: 'Evaluate GAC > 80'},
      {value: 'wide_lt_50', label: 'Evaluate Wide < 50'},
      {value: 'wide_50_70', label: 'Evaluate Wide 50 – 70'},
      {value: 'wide_lt_70', label: 'Evaluate Wide < 70'},
      {value: 'wide_70_80', label: 'Evaluate Wide 70 – 80'},
      {value: 'wide_gt_80', label: 'Evaluate Wide > 80'},
      {value: 'cahra', label: 'CAHRA'},
      {value: 'non_cahra', label: 'Non-CAHRA'},
    ],
    riskLabels: {
      '-1': 'N/A',
      '0': 'No risk',
      '1': 'Low',
      '2': 'Medium',
      '3': 'High',
      '4': 'Critical',
    },
    flagLabels: {
      0: 'None',
      1: 'Green',
      2: 'Yellow',
      3: 'Red',
      4: 'Critical',
      11: 'Green (not shown)',
      12: 'Yellow (not shown)',
      13: 'Red (not shown)',
      14: 'Critical (not shown)',
      21: 'Green (forced)',
      22: 'Yellow (forced)',
      23: 'Red (forced)',
      24: 'Critical (forced)',
    },
    flagColors: {
      1: 'success',
      2: 'warning',
      3: 'danger',
      4: 'critical',
      11: 'success',
      12: 'warning',
      13: 'danger',
      14: 'critical',
      21: 'success',
      22: 'warning',
      23: 'danger',
      24: 'critical',
    },
    icons: {
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiCheck,
      mdiCircle,
      mdiChevronUp,
      mdiChevronDown,
      mdiChevronRight,
      mdiFlag,
    }
  }),
  computed: {
    ...mapState(useCoreStore, {
      countries: 'getCountries',
    }),
    headers() {
      return [
        {
          text: this.$t('options'),
          value: 'value'
        },
        {text: this.$t('risk'), value: 'risk'},
        {text: this.$t('flag'), value: 'flag'},
        {text: this.$t('combos'), value: 'combinations'},
        ...this.field.automation?.type === 'number' ? [{text: this.$t('range'), value: 'range'}] : [],
        {text: this.$t('actions'), value: 'actions', align: 'right'},
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add_options') : this.$t('edit_options');
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.matrix = [];
      if (this.field.options && this.field.options.length > 0) {
        for (const option of this.field.options) {
          if (!option.id) {
            option.id = short.generate();
          }
          this.matrix.push(option);
        }
      }
    },
    parseIndexOption(v) {
      const category = this.countryCategories.find(f => f.value === v);
      return category?.label ?? v;
    },
    parseCountries(v) {
      if (Array.isArray(v)) {
        return v.map(i => {
          const country = this.countries.find(country => country.code === i);
          return country ? country.name : v;
        }).join(', ');
      } else {
        const country = this.countries.find(country => country.code === v);
        return country.name;
      }
    },
    getIndex(item) {
      let index = -1;
      for (let idx = 0; idx < this.matrix.length; idx += 1) {
        if (item.id === this.matrix[idx].id) {
          index = idx;
        }
      }
      return index;
    },
    move(up, item) {
      let from = this.getIndex(item);
      let to = (up) ? from - 1 : from + 1;
      if (from > -1) {
        this.matrix.splice(to, 0, this.matrix.splice(from, 1)[0]);
      }
      this.storeOptions();
    },
    editItem(item) {
      this.editedIndex = this.matrix.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.matrix.indexOf(item);
      if (confirm(this.$t('confirm_delete_row'))) {
        this.matrix.splice(index, 1);
        this.storeOptions();
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (!this.editedItem.id) {
        this.editedItem.id = short.generate();
      }
      if (this.editedItem.value) {
        if (this.editedIndex > -1) {
          Object.assign(this.matrix[this.editedIndex], this.editedItem);
        } else {
          this.matrix.push(this.editedItem);
        }
        this.storeOptions();
      }
      this.close();
    },
    storeOptions() {
      this.field.options = this.matrix;
      this.$emit('changed');
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.v-chip-group .v-chip {
  margin: 0 8px 0 0;
}
</style>
