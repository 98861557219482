import axios from 'axios';
import cookies from 'js-cookie';

const base_url = (window.location.hostname === 'localhost') ? 'http://localhost:3005/inside-api' : '/inside-api';

const getData = async (path) => {
    let url = base_url + path;
    try {
        const config = {
            headers: {
                'Authorization': `Bearer ${cookies.get('ev-app-t')}`,
            }
        };
        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        console.error('api: getData', url, error);
        return [];
    }
};

const postData = async (path, data) => {
    let url = base_url + path;
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.get('ev-app-t')}`,
            }
        };
        const response = await axios.post(url, data, config);
        return response.data;
    } catch (error) {
        console.error('api: postData', url, error);
        return [];
    }
};

const getModel = async id => {
    let def = {};
    try {
        def = await getData(`/v1/admin/riskanalyses/models/${id}`);
    } catch (error) {
        console.error('api: getModel', id, error);
    }
    return def;
};

const saveModel = async model => {
    let resp = {
        success: false
    };
    try {
        resp = await postData(`/v1/admin/riskanalyses/models/${model.id}`, model);
    } catch (error) {
        console.error('api: saveModel', model.id, error);
    }
    return resp;
};

const getCountries = async (lang) => {
    try {
        lang ??= 'en';
        return await getData(`/v1/classifications/countries/${lang}`);
    } catch (error) {
        console.error('api: getCountries', error);
        return [];
    }
};

const getNaceCodes = async (lang) => {
    try {
        lang ??= 'en';
        return await getData(`/v1/classifications/nace/${lang}`);
    } catch (error) {
        console.error('api: getNaceCodes', error);
        return [];
    }
};

export default {
    getModel,
    saveModel,
    getCountries,
    getNaceCodes,
};
