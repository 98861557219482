import shortid from 'shortid'

export default {
    generate: function () {
        return shortid.generate().concat(base36Timestamp()).replace(/[_-]/g, '').toLowerCase();
    }
};

function base36Timestamp() {
    return Math.floor((new Date().getTime() - new Date(2020,1,1)) / 60000).toString(36);
}