<template>
  <router-view v-if="activateRouter"></router-view>
</template>

<script>
import axios from 'axios';
import cookies from 'js-cookie';

export default {
  name: 'App',
  data: () => ({
    activateRouter: false,
  }),
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.token) {
          const appToken = this.$route.query.token;
          const debug = true;
          const baseUrl = window.location.hostname === 'localhost' && debug ? 'http://localhost:3005' : '';
          axios.post(`${baseUrl}/auth/token/create`, {appToken}).then(res => {
            if (res.data?.success) {
              cookies.set('ev-app-t', res.data.token, {secure: true, sameSite: 'lax'});
              cookies.set('ev-app-env', res.data.env, {secure: true, sameSite: 'lax'});
              this.$router.replace({query: undefined});
            } else {
              this.$router.push('/');
            }
          });
        } else {
          this.activateRouter = true;
        }
      }
    }
  },
}
</script>
