<template>
  <v-input v-model="matrix">
    <v-data-table
        :headers="headers"
        :items="matrix"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        dense
        class="w-100">
      <template v-slot:item.fieldId="{item}">
        {{ item.fieldTitle || item.fieldId }}
      </template>
      <template v-slot:item.optionId="{item}">
        {{ item.optionValue || item.optionId }}
      </template>
      <template v-slot:item.flag="{item}">
        <v-icon v-if="item.flag" small :color="flagColors[item.flag]" class="mr-1">
          {{ item.flag === 4 || item.flag === 14 || item.flag === 24 ? $vuetify.icons.values.flagAlert : icons.mdiFlag }}
        </v-icon>
        <span v-if="item.flag">{{ flagLabels[item.flag] }}</span>
      </template>
      <template v-slot:footer>
        <v-toolbar flat dense color="white">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{on}">
              <v-btn fab x-small color="info" dark v-on="on">
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-select v-model="editedItem.fieldId"
                          :items="fields.filter(f => f.options && f.options.length > 0 && f.title)"
                          item-text="title" item-value="id"
                          :label="$t('field')">
                </v-select>
                <v-select v-if="editedItem.fieldId" v-model="editedItem.optionId"
                          :items="fieldOptions(editedItem.fieldId)" item-text="parsedValue" item-value="id"
                          :label="$t('value')">
                </v-select>
                <v-slider
                    v-if="editedItem.optionId"
                    v-model="editedItem.riskElevation"
                    :thumb-size="16"
                    :max="3"
                    :min="-3"
                    thumb-label="always"
                    hide-details
                    thumb-color="info"
                    :label="$t('risk_elevation')"
                    class="mt-4 mb-2"
                ></v-slider>
                <v-select v-if="editedItem.optionId" v-model="editedItem.flag" :items="flagTypes" item-text="label"
                          item-value="value"
                          :label="$t('flag')">
                  <template slot="append">
                    <v-icon :color="flagColors[editedItem.flag]">
                      {{
                        editedItem.flag === 4 || editedItem.flag === 14 || editedItem.flag === 24 ? $vuetify.icons.values.flagAlert : icons.mdiFlag
                      }}
                    </v-icon>
                  </template>
                </v-select>
              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary" text @click="close">{{ $t('button_cancel') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="save">{{ $t('button_save') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <span class="text-no-wrap">
            <v-btn icon x-small :disabled="Boolean(getIndex(item) === 0)" class="mr-2"
                   @click="move(true, item)">
                <v-icon small>
                    {{ icons.mdiChevronUp }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small :disabled="(getIndex(item) === matrix.length - 1)" class="mr-2"
                   @click="move(false, item)">
                <v-icon small>
                    {{ icons.mdiChevronDown }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small class="mr-2" @click="editItem(item)">
                <v-icon small>
                    {{ icons.mdiPencil }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small @click="deleteItem(item)">
                <v-icon small>
                    {{ icons.mdiDelete }}
                </v-icon>
            </v-btn>
        </span>
      </template>
    </v-data-table>
  </v-input>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiCheck,
  mdiCircle,
  mdiChevronUp,
  mdiChevronDown,
  mdiFlag,
} from '@mdi/js';

export default {
  name: 'ComboTable',
  props: {
    option: Object,
    field: Object,
    fields: Array,
    countries: Array,
  },
  data: () => ({
    dialog: false,
    search: null,
    matrix: [],
    editedIndex: -1,
    editedItem: {
      fieldId: '',
      riskElevation: 0,
      flag: 0,
    },
    defaultItem: {
      fieldId: '',
      riskElevation: 0,
      flag: 0,
    },
    requiredRule: [
      v => !!v || 'Required',
    ],
    flagTypes: [
      {value: 0, label: 'No flag'},
      {value: 1, label: 'Green'},
      {value: 2, label: 'Yellow'},
      {value: 3, label: 'Red'},
      {value: 4, label: 'Critical'},
      {value: 11, label: 'Green (not shown)'},
      {value: 12, label: 'Yellow (not shown)'},
      {value: 13, label: 'Red (not shown)'},
      {value: 14, label: 'Critical (not shown)'},
      {value: 21, label: 'Green (forced)'},
      {value: 22, label: 'Yellow (forced)'},
      {value: 23, label: 'Red (forced)'},
      {value: 24, label: 'Critical (forced)'},
    ],
    flagLabels: {
      0: 'None',
      1: 'Green',
      2: 'Yellow',
      3: 'Red',
      4: 'Critical',
      11: 'Green (not shown)',
      12: 'Yellow (not shown)',
      13: 'Red (not shown)',
      14: 'Critical (not shown)',
      21: 'Green (forced)',
      22: 'Yellow (forced)',
      23: 'Red (forced)',
      24: 'Critical (forced)',
    },
    flagColors: {
      1: 'success',
      2: 'warning',
      3: 'danger',
      4: 'critical',
      11: 'success',
      12: 'warning',
      13: 'danger',
      14: 'critical',
      21: 'success',
      22: 'warning',
      23: 'danger',
      24: 'critical',
    },
    icons: {
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiCheck,
      mdiCircle,
      mdiChevronUp,
      mdiChevronDown,
      mdiFlag,
    }
  }),
  computed: {
    headers() {
      return [
        {text: this.$t('field'), value: 'fieldId'},
        {text: this.$t('option'), value: 'optionId'},
        {text: this.$t('risk_elevation'), value: 'riskElevation'},
        {text: this.$t('flag'), value: 'flag'},
        {text: this.$t('actions'), value: 'actions', align: 'right'},
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add_combo') : this.$t('edit_combo');
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    fieldOptions(fieldId) {
      const field = this.fields.find(f => f.id === fieldId);
      const options = field?.options ?? [];
      for (const option of options) {
        if (Array.isArray(option.value)) {
          if (option.value[0].length === 3) {
            option.value = this.parseCountries(option.value);
          }
          option.parsedValue = option.value.join(', ');
        } else {
          if (option.value.length === 3) {
            option.value = this.parseCountries(option.value);
          }
          option.parsedValue = option.value;
        }
      }
      return options;
    },
    parseCountries(v) {
      if (Array.isArray(v)) {
        return v.map(i => {
          const country = this.countries.find(country => country.code === i);
          return country ? country.name : v;
        }).join(', ');
      } else {
        const country = this.countries.find(country => country.code === v);
        return country.name;
      }
    },
    initialize() {
      this.matrix = [];
      if (this.option.combinations && this.option.combinations.length > 0) {
        for (const combo of this.option.combinations) {
          this.matrix.push(combo);
        }
      }
    },
    getIndex(item) {
      let index = -1;
      for (let idx = 0; idx < this.matrix.length; idx += 1) {
        if (item.id === this.matrix[idx].id) {
          index = idx;
        }
      }
      return index;
    },
    move(up, item) {
      let from = this.getIndex(item);
      let to = (up) ? from - 1 : from + 1;
      if (from > -1) {
        this.matrix.splice(to, 0, this.matrix.splice(from, 1)[0]);
      }
      this.storeOptions();
    },
    editItem(item) {
      this.editedIndex = this.matrix.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.matrix.indexOf(item);
      if (confirm(this.$t('confirm_delete_row'))) {
        this.matrix.splice(index, 1);
        this.storeOptions();
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedItem.fieldId && this.editedItem.optionId) {
        this.editedItem.fieldTitle = this.fields.find(f => f.id === this.editedItem.fieldId).title;
        let fieldOptions = this.fieldOptions(this.editedItem.fieldId);
        this.editedItem.optionValue = fieldOptions.find(o => o.id === this.editedItem.optionId).value;
        if (this.editedIndex > -1) {
          Object.assign(this.matrix[this.editedIndex], this.editedItem);
        } else {
          this.matrix.push(this.editedItem);
        }
      }
      this.storeOptions();
      this.close();
    },
    storeOptions() {
      this.option.combinations = this.matrix;
      this.$emit('changed');
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.v-chip-group .v-chip {
  margin: 0 8px 0 0;
}
</style>
